<template>
  <div
    v-show="showRoomsList"
    class="vac-rooms-container"
    :class="{
      'vac-rooms-container-full': isMobile,
      'vac-app-border-r': !isMobile
    }"
  >
    <slot name="rooms-header" />

    <slot name="rooms-list-search">
      <rooms-search
        :rooms="rooms"
        :loading-rooms="loadingRooms"
        :text-messages="textMessages"
        :show-search="showSearch"
        :show-add-room="showAddRoom"
        @search-room="searchRoom"
        @add-room="emit('add-room')"
      >
        <template v-for="(_, name) in $slots" #[name]="slotData">
          <slot :name="name" v-bind="slotData" />
        </template>
      </rooms-search>
    </slot>

    <loader :show="loadingRooms" type="rooms">
      <template v-for="(_, name) in $slots" #[name]="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>
    </loader>

    <div v-if="!loadingRooms && !rooms.length" class="vac-rooms-empty">
      <slot name="rooms-empty">
        {{ textMessages.ROOMS_EMPTY }}
      </slot>
    </div>

    <div v-if="!loadingRooms" id="rooms-list" class="vac-room-list">
      <div
        v-for="fRoom in filteredRooms"
        :id="fRoom.roomId"
        :key="fRoom.roomId"
        class="vac-room-item"
        :class="{ 'vac-room-selected': selectedRoomId === fRoom.roomId }"
        @click="openRoom(fRoom)"
      >
        <room-content
          :current-user-id="currentUserId"
          :room="fRoom"
          :text-formatting="textFormatting"
          :link-options="linkOptions"
          :text-messages="textMessages"
          :room-actions="roomActions"
          @room-action-handler="emit('room-action-handler', $event)"
        >
          <template v-for="(_, name) in $slots" #[name]="slotData">
            <slot :name="name" v-bind="slotData" />
          </template>
        </room-content>
      </div>
      <transition name="vac-fade-message">
        <div v-if="rooms.length && !loadingRooms" id="infinite-loader-rooms">
          <loader :show="showLoader" :infinite="true" type="infinite-rooms">
            <template v-for="(_, name) in $slots" #[name]="slotData">
              <slot :name="name" v-bind="slotData" />
            </template>
          </loader>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, computed } from 'vue'
import Loader from '../../components/Loader/Loader'
import RoomsSearch from './RoomsSearch/RoomsSearch'
import RoomContent from './RoomContent/RoomContent'
import filteredItems from '../../utils/filter-items'

const props = defineProps({
  currentUserId: { type: [String, Number], required: true },
  textMessages: { type: Object, required: true },
  showRoomsList: { type: Boolean, required: true },
  showSearch: { type: Boolean, required: true },
  showAddRoom: { type: Boolean, required: true },
  textFormatting: { type: Object, required: true },
  linkOptions: { type: Object, required: true },
  isMobile: { type: Boolean, required: true },
  rooms: { type: Array, required: true },
  loadingRooms: { type: Boolean, required: true },
  roomsLoaded: { type: Boolean, required: true },
  room: { type: Object, required: true },
  customSearchRoomEnabled: { type: [Boolean, String], default: false },
  roomActions: { type: Array, required: true },
  scrollDistance: { type: Number, required: true }
})

const emit = defineEmits([
  'add-room',
  'search-room',
  'room-action-handler',
  'loading-more-rooms',
  'fetch-room',
  'fetch-more-rooms'
])

const filteredRooms = ref(props.rooms || [])
const observer = ref(null)
const showLoader = ref(true)
const loadingMoreRooms = ref(false)
const selectedRoomId = ref('')

watch(() => props.rooms, (newVal, oldVal) => {
  filteredRooms.value = newVal
  if (newVal.length !== oldVal.length || props.roomsLoaded) {
    loadingMoreRooms.value = false
  }
}, { deep: true })

watch(() => props.loadingRooms, (val) => {
  if (!val) {
    setTimeout(() => initIntersectionObserver())
  }
})

watch(() => loadingMoreRooms.value, (val) => {
  emit('loading-more-rooms', val)
})

watch(() => props.roomsLoaded, (val) => {
  if (val) {
    loadingMoreRooms.value = false
    if (!props.loadingRooms) {
      showLoader.value = false
    }
  }
}, { immediate: true })

watch(() => props.room, (val) => {
  if (val && !props.isMobile) selectedRoomId.value = val.roomId
}, { immediate: true })

const initIntersectionObserver = () => {
  if (observer.value) {
    showLoader.value = true
    observer.value.disconnect()
  }

  const loader = document.querySelector('#infinite-loader-rooms')

  if (loader) {
    const options = {
      root: document.querySelector('#rooms-list'),
      rootMargin: `${props.scrollDistance}px`,
      threshold: 0
    }

    observer.value = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        loadMoreRooms()
      }
    }, options)

    observer.value.observe(loader)
  }
}

const searchRoom = (ev) => {
  if (props.customSearchRoomEnabled) {
    emit('search-room', ev.target.value)
  } else {
    filteredRooms.value = filteredItems(
      props.rooms,
      'roomName',
      ev.target.value
    )
  }
}

const openRoom = (room) => {
  if (room.roomId === props.room.roomId && !props.isMobile) return
  if (!props.isMobile) selectedRoomId.value = room.roomId
  emit('fetch-room', { room })
}

const loadMoreRooms = () => {
  if (loadingMoreRooms.value) return

  if (props.roomsLoaded) {
    loadingMoreRooms.value = false
    showLoader.value = false
    return
  }

  emit('fetch-more-rooms')
  loadingMoreRooms.value = true
}

onMounted(() => {
  if (!props.loadingRooms) {
    initIntersectionObserver()
  }
})
</script>