<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router'
const router = useRouter()
import { useNuxtApp } from '#app';
import { register } from 'vue-advanced-chat'
register()
import ChatContainer from '@/components/src/lib/ChatWindow.vue'

import FirestoreManager from './database/firestore';
import FirebaseStatusManager from './database/firebase';
import FirebaseStorageManager from './database/storage';


const nuxtApp = useNuxtApp();
let firestoreService = new FirestoreManager(nuxtApp.$firestore)
let firebaseService = new FirebaseStatusManager(nuxtApp.$realtimeDb)
let storageService = new FirebaseStorageManager(nuxtApp.$storage)

import { parseTimestamp, formatTimestamp } from './utils/dates';

const props = defineProps({
  currentUserId: { type: String, required: true },
  theme: { type: String, required: true },
  isDevice: { type: Boolean, required: true },
  // project: {type: Object, required:true }
});

const emit = defineEmits(['show-demo-options', 'messageSelectionActionHandler', 'selected-room']);

// PROJECT INIT

// const projectName = ref(props.project.name);
// const projectId = ref(props.project.id);
// const projectSubdomain = ref(props.project.subdomain);
const showSendIcon = ref(true)
const roomsPerPage = ref(15);
const rooms = ref([]);
const roomId = ref('');
const startRooms = ref(null);
const endRooms = ref(null);
const roomsLoaded = ref(false);
const loadingRooms = ref(true);
const allUsers = ref([]);
const loadingLastMessageByRoom = ref(0);
const roomsLoadedCount = ref(0);
const selectedRoom = ref(null);
const messagesPerPage = ref(20);
const messages = ref([]);
const messagesLoaded = ref(false);
const roomMessage = ref('');
const lastLoadedMessage = ref(null);
const previousLastLoadedMessage = ref(null);
const roomsListeners = ref([]);
const listeners = ref([]);
const typingMessageCache = ref('');
const disableForm = ref(false);
const addNewRoom = ref(null);
const addRoomUsername = ref('');
const inviteRoomId = ref(null);
const invitedUsername = ref('');
const removeRoomId = ref(null);
const removeUserId = ref('');
const removeUsers = ref([]);

const roomActions = [
  { name: 'inviteUser', title: 'Invite User' },
  { name: 'removeUser', title: 'Remove User' },
  { name: 'deleteRoom', title: 'Delete Room' }
];

const menuActions = [
  { name: 'inviteUser', title: 'Invite User' },
  { name: 'removeUser', title: 'Remove User' },
  { name: 'deleteRoom', title: 'Delete Room' }
];

const messageSelectionActions = [
  { name: 'deleteMessages', title: 'Delete' },
  { name: 'callbrx', title: 'BRX' },
  { name: 'callGuidedbrx', title: 'Guided BRX' },
];

const styles = { container: { borderRadius: '4px' } };

const templatesText = [
  { tag: 'help', text: 'This is the help' },
  { tag: 'action', text: 'This is the action' },
  { tag: 'action 2', text: 'This is the second action' }
];

const loadedRooms = computed(() => rooms.value.slice(0, roomsLoadedCount.value));

const screenHeight = computed(() => props.isDevice ? window.innerHeight + 'px' : 'calc(75vh - 80px)');

onMounted(async () => {
  await fetchRooms();
  firebaseService.updateUserOnlineStatus(props.currentUserId);
});

function resetRooms() {
  loadingRooms.value = true;
  loadingLastMessageByRoom.value = 0;
  roomsLoadedCount.value = 0;
  rooms.value = [];
  roomsLoaded.value = true;
  startRooms.value = null;
  endRooms.value = null;
  roomsListeners.value.forEach(listener => listener());
  roomsListeners.value = [];
  resetMessages();
}

function resetMessages() {
  messages.value = [];
  messagesLoaded.value = false;
  lastLoadedMessage.value = null;
  previousLastLoadedMessage.value = null;
  listeners.value.forEach(listener => listener());
  listeners.value = [];
}

async function fetchRooms() {
  resetRooms();
  await fetchMoreRooms();
}

async function fetchMoreRooms() {
  if (endRooms.value && !startRooms.value) {
    roomsLoaded.value = true;
    return;
  }

	console.log("pre query")
	console.log(props.currentUserId)
	console.log(roomsPerPage.value)
	console.log(startRooms.value)

  const query = firestoreService.roomsQuery(
    props.currentUserId,
    roomsPerPage.value,
    startRooms.value
  );

  console.log("+_+_+_+_+_+_ checking inital fetch rooms query")
  console.log(query)

  const { data, docs } = await firestoreService.getRooms(query);

  console.log("ACUTAL DATA")
  console.log(data)
  console.log(docs)

  roomsLoaded.value = data.length === 0 || data.length < roomsPerPage.value;

  if (startRooms.value) endRooms.value = startRooms.value;
  startRooms.value = docs[docs.length - 1];

  const roomUserIds = [];
  data.forEach(room => {
    room.users.forEach(userId => {
      const foundUser = allUsers.value.find(user => user?._id === userId);
      if (!foundUser && roomUserIds.indexOf(userId) === -1) {
        roomUserIds.push(userId);
      }
    });
  });

  const rawUsers = [];
  roomUserIds.forEach(userId => {
    const promise = firestoreService.getUser(userId);
    rawUsers.push(promise);
  });

  allUsers.value = [...allUsers.value, ...(await Promise.all(rawUsers))];

  const roomList = {};
  data.forEach(room => {
    roomList[room.id] = { ...room, users: [] };

    room.users.forEach(userId => {
      const foundUser = allUsers.value.find(user => user?._id === userId);
      if (foundUser) roomList[room.id].users.push(foundUser);
    });
  });

  const formattedRooms = [];

  Object.keys(roomList).forEach(key => {
    const room = roomList[key];

    const roomContacts = room.users.filter(
      user => user._id !== props.currentUserId
    );

    room.roomName =
      roomContacts.map(user => user.username).join(', ') || '';

    const roomAvatar = roomContacts[0]?.avatar;

    formattedRooms.push({
      ...room,
      roomId: key,
      avatar: roomAvatar,
      index: room.lastUpdated.seconds,
      lastMessage: {
        content: 'Room created',
        timestamp: formatTimestamp(
          new Date(room.lastUpdated.seconds),
          room.lastUpdated
        )
      }
    });
  });

  rooms.value = rooms.value.concat(formattedRooms);
  formattedRooms.forEach(room => listenLastMessage(room));

  if (!rooms.value.length) {
    loadingRooms.value = false;
    roomsLoadedCount.value = 0;
  }

  listenUsersOnlineStatus(formattedRooms);
  listenRooms(query);
}

function listenLastMessage(room) {
  const listener = firestoreService.listenLastMessage(
    room.roomId,
    messages => {
      messages.forEach(message => {
        const lastMessage = formatLastMessage(message, room);
        const roomIndex = rooms.value.findIndex(
          r => room.roomId === r.roomId
        );
        if (roomIndex !== -1) {
          rooms.value[roomIndex].lastMessage = lastMessage;
          rooms.value = [...rooms.value];
        }
      });
      if (loadingLastMessageByRoom.value < rooms.value.length) {
        loadingLastMessageByRoom.value++;

        if (loadingLastMessageByRoom.value === rooms.value.length) {
          loadingRooms.value = false;
          roomsLoadedCount.value = rooms.value.length;
        }
      }
    }
  );

  roomsListeners.value.push(listener);
}

function formatLastMessage(message, room) {
  if (!message.timestamp) return;

  let content = message.content;
  if (message.files?.length) {
    const file = message.files[0];
    content = `${file.name}.${file.extension || file.type}`;
  }

  const username =
    message.sender_id !== props.currentUserId
      ? room.users.find(user => message.sender_id === user._id)?.username
      : '';

  return {
    ...message,
    ...{
      _id: message.id,
      content,
      senderId: message.sender_id,
      timestamp: formatTimestamp(
        new Date(message.timestamp.seconds * 1000),
        message.timestamp
      ),
      username: username,
      distributed: true,
      seen: message.sender_id === props.currentUserId ? message.seen : null,
      new:
        message.sender_id !== props.currentUserId &&
        (!message.seen || !message.seen[props.currentUserId])
    }
  };
}

function fetchMessages({ room, options = {} }) {
  emit('show-demo-options', false);

  if (options.reset) {
    resetMessages();
  }

  if (previousLastLoadedMessage.value && !lastLoadedMessage.value) {
    messagesLoaded.value = true;
    return;
  }

  emit('selected-room', room.roomId);
  selectedRoom.value = room.roomId;

  firestoreService
    .getMessages(room.roomId, messagesPerPage.value, lastLoadedMessage.value)
    .then(({ data, docs }) => {
      if (selectedRoom.value !== room.roomId) return;

      if (data.length === 0 || data.length < messagesPerPage.value) {
        setTimeout(() => {
          messagesLoaded.value = true;
        }, 0);
      }

      if (options.reset) messages.value = [];

      const newMessages = data.map(message => formatMessage(room, message));
      messages.value = [...newMessages.reverse(), ...messages.value];

      if (lastLoadedMessage.value) {
        previousLastLoadedMessage.value = lastLoadedMessage.value;
      }
      lastLoadedMessage.value = docs[docs.length - 1];

      listenMessages(room);
    });
}

function listenMessages(room) {
  const listener = firestoreService.listenMessages(
    room.roomId,
    lastLoadedMessage.value,
    previousLastLoadedMessage.value,
    newMessages => {
      newMessages.forEach(message => {
        const formattedMessage = formatMessage(room, message);
        const messageIndex = messages.value.findIndex(
          m => m._id === message.id
        );

        if (messageIndex === -1) {
          messages.value = [...messages.value, formattedMessage];
        } else {
          messages.value[messageIndex] = formattedMessage;
          messages.value = [...messages.value];
        }

        markMessagesSeen(room, message);
      });
    }
  );
  listeners.value.push(listener);
}

function markMessagesSeen(room, message) {
  if (
    message.sender_id !== props.currentUserId &&
    (!message.seen || !message.seen[props.currentUserId])
  ) {
    firestoreService.updateMessage(room.roomId, message.id, {
      [`seen.${props.currentUserId}`]: new Date()
    });
  }
}

function formatMessage(room, message) {
  const formattedMessage = {
    ...message,
    ...{
      senderId: message.sender_id,
      _id: message.id,
      seconds: message.timestamp.seconds,
      timestamp: parseTimestamp(message.timestamp, 'HH:mm'),
      date: parseTimestamp(message.timestamp, 'DD MMMM YYYY'),
      username: room.users.find(user => message.sender_id === user._id)
        ?.username,
      distributed: true
    }
  };

  if (message.replyMessage) {
    formattedMessage.replyMessage = {
      ...message.replyMessage,
      ...{
        senderId: message.replyMessage.sender_id
      }
    };
  }

  return formattedMessage;
}

async function sendMessage({ content, roomId, files, replyMessage }) {
  const message = {
    sender_id: props.currentUserId,
    content,
    timestamp: new Date()
  };

  if (files) {
    message.files = formattedFiles(files);
  }

  if (replyMessage) {
    message.replyMessage = {
      _id: replyMessage._id,
      content: replyMessage.content,
      sender_id: replyMessage.senderId
    };

    if (replyMessage.files) {
      message.replyMessage.files = replyMessage.files;
    }
  }

  const { id } = await firestoreService.addMessage(roomId, message);

  const upload_urls = []
  let additions

  if (files) {
    for (let index = 0; index < files.length; index++) {
      let url = await uploadFile({ file: files[index], messageId: id, roomId });
	  upload_urls.push(url)
    }
  }

  firestoreService.updateRoom(roomId, { lastUpdated: new Date() });

  showSendIcon.value = false

   if (upload_urls.length > 0) {
    additions = `::: You currently have access to these URL's. Make sure to use them 1:1 if you do and make a note to never shorten them :) --- ${upload_urls.join(', ')}`;
  } else {
	  additions = ''
  }

  let options = {
    verbose: true
  }


  const requestBody = {
	"initialChoice": "CREATE_NEW",
	// "projectName": "something-new",
	// "buildTask": tempReq,
	// "projectName": projectName.value,
	"buildTask": `${content}${additions}`,
	"projectReference": `${roomId}`,
	"accessToken": `brxf28db5ecbce53c4c7660eed2ce4d89640aa01ec54bc7846b160ac4e6e6d84729`,
	"options": options,
	}

   console.log("Sending" , requestBody)

  try {
    const response = await fetch('http://localhost:3030/start-process', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody),
    })

	const message = {
		sender_id: props.currentUserId,
		content,
		timestamp: new Date()
	};

	// const message = {
	// 	sender_id: props.currentUserId,
	// 	content,
	// 	timestamp: new Date()
	// };

    if (response.ok) {
      const result = await response.json()
	  
      message.content = JSON.stringify(result, null, 2)
		const { id } = await firestoreService.addMessage(roomId, message);
		firestoreService.updateRoom(roomId, { lastUpdated: new Date() });
    // Redirect to the project page
    if(result.success == true){
      console.log("About to send")
      router.push({path: `/project/${result.project_name}`})
    }
    message.content.project_name 
    } else {
      message.content = `Error: ${response.status} ${response.statusText}`
		const { id } = await firestoreService.addMessage(roomId, message);
		firestoreService.updateRoom(roomId, { lastUpdated: new Date() });
    }
  } catch (error) {
    message.content = `Error: ${error.details}`
	const { id } = await firestoreService.addMessage(roomId, message);
	firestoreService.updateRoom(roomId, { lastUpdated: new Date() });
  } finally {
    showSendIcon.value = true
  }
}

async function editMessage({ messageId, newContent, roomId, files }) {
  const newMessage = { edited: new Date() };
  newMessage.content = newContent;

  newMessage.data = { value1: 3, value2: 'string' };

  console.log(newMessage.content);

  if (files) {
    newMessage.files = formattedFiles(files);
  } else {
    newMessage.files = firestoreService.deleteDbField;
  }

  await firestoreService.updateMessage(roomId, messageId, newMessage);

  if (files) {
    for (let index = 0; index < files.length; index++) {
      if (files[index]?.blob) {
        await uploadFile({ file: files[index], messageId, roomId });
      }
    }
  }
}

async function deleteMessage({ message, roomId }) {
  await firestoreService.updateMessage(roomId, message._id, {
    deleted: new Date()
  });

  const { files } = message;

  if (files) {
    files.forEach(file => {
      storageService.deleteFile(props.currentUserId, message._id, file);
    });
  }
}

async function uploadFile({ file, messageId, roomId }) {
  return new Promise((resolve) => {
    let type = file.extension || file.type;
    if (type === 'svg' || type === 'pdf') {
      type = file.type;
    }
    storageService.listenUploadImageProgress(
      props.currentUserId,
      messageId,
      file,
      type,
      (progress) => {
        updateFileProgress(messageId, file.localUrl, progress);
      },
      (_error) => {
        resolve(null); // Resolve with null in case of error
      },
      async (url) => {
        const message = await firestoreService.getMessage(roomId, messageId);
        message.files.forEach((f) => {
          if (f.url === file.localUrl) {
            f.url = url;
          }
        });
        await firestoreService.updateMessage(roomId, messageId, {
          files: message.files,
        });
        resolve(url); // Resolve with the URL
      }
    );
  });
}

function updateFileProgress(messageId, fileUrl, progress) {
  if (!messages.value) return;

  const messageIndex = messages.value.findIndex(message => message._id === messageId);
  if (messageIndex === -1) return;

  const message = messages.value[messageIndex];
  if (!message.files) return;

  const fileIndex = message.files.findIndex(file => file.url === fileUrl);
  if (fileIndex === -1) return;

  messages.value[messageIndex].files[fileIndex].progress = progress;
  messages.value = [...messages.value];
}

function formattedFiles(files) {
  const formattedFiles = [];

  files.forEach(file => {
    const messageFile = {
      name: file.name,
      size: file.size,
      type: file.type,
      extension: file.extension || file.type,
      url: file.url || file.localUrl
    };

    if (file.audio) {
      messageFile.audio = true;
      messageFile.duration = file.duration;
    }

    formattedFiles.push(messageFile);
  });

  return formattedFiles;
}

function openFile({ file }) {
  window.open(file.file.url, '_blank');
}

async function openUserTag({ user }) {
  let roomId;

  rooms.value.forEach(room => {
    if (room.users.length === 2) {
      const userId1 = room.users[0]._id;
      const userId2 = room.users[1]._id;
      if (
        (userId1 === user._id || userId1 === props.currentUserId) &&
        (userId2 === user._id || userId2 === props.currentUserId)
      ) {
        roomId = room.roomId;
      }
    }
  });

  if (roomId) {
    roomId.value = roomId;
    return;
  }

  const query1 = await firestoreService.getUserRooms(
    props.currentUserId,
    user._id
  );

  if (query1.data.length) {
    return loadRoom(query1);
  }

  const query2 = await firestoreService.getUserRooms(
    user._id,
    props.currentUserId
  );

  if (query2.data.length) {
    return loadRoom(query2);
  }

  const users =
    user._id === props.currentUserId
      ? [props.currentUserId]
      : [user._id, props.currentUserId];

  const room = await firestoreService.addRoom({
    users: users,
    lastUpdated: new Date()
  });
  roomId.value = room.id;
  fetchRooms();
}

async function loadRoom(query) {
  query.forEach(async room => {
    if (loadingRooms.value) return;
    await firestoreService.updateRoom(room.id, { lastUpdated: new Date() });
    roomId.value = room.id;
    fetchRooms();
  });
}

function menuActionHandler({ action, roomId }) {
  switch (action.name) {
    case 'inviteUser':
      return inviteUser(roomId);
    case 'removeUser':
      return removeUser(roomId);
    case 'deleteRoom':
      return deleteRoom(roomId);
  }
}

function messageSelectionActionHandler({ action, messages, roomId }) {
  emit('messageSelectionActionHandler', { action, messages, roomId });
  switch (action.name) {
    case 'deleteMessages':
      messages.forEach(message => {
        deleteMessage({ message, roomId });
      });
  }
}

async function sendMessageReaction({ reaction, remove, messageId, roomId }) {
  firestoreService.updateMessageReactions(
    roomId,
    messageId,
    props.currentUserId,
    reaction.unicode,
    remove ? 'remove' : 'add'
  );
}

function typingMessage({ message, roomId }) {
  if (roomId) {
    if (message?.length > 1) {
      typingMessageCache.value = message;
      return;
    }

    if (message?.length === 1 && typingMessageCache.value) {
      typingMessageCache.value = message;
      return;
    }

    typingMessageCache.value = message;

    firestoreService.updateRoomTypingUsers(
      roomId,
      props.currentUserId,
      message ? 'add' : 'remove'
    );
  }
}

async function listenRooms(query) {
  const listener = firestoreService.listenRooms(query, updatedRooms => {
    updatedRooms.forEach(updatedRoom => {
      const roomIndex = rooms.value.findIndex(r => r.roomId === updatedRoom.id);
      if (roomIndex !== -1) {
        rooms.value[roomIndex].typingUsers = updatedRoom.typingUsers;
        rooms.value[roomIndex].index = updatedRoom.lastUpdated.seconds;
      }
    });
    rooms.value = [...rooms.value];
  });
  roomsListeners.value.push(listener);
}

function listenUsersOnlineStatus(fetchedRooms) {
  fetchedRooms.forEach(room => {
    room.users.forEach(user => {
      const listener = firebaseService.firebaseListener(
        firebaseService.userStatusRef(user._id),
        snapshot => {
          if (!snapshot || !snapshot.val()) return;

          const lastChanged = formatTimestamp(
            new Date(snapshot.val().lastChanged),
            new Date(snapshot.val().lastChanged)
          );

          user.status = { ...snapshot.val(), lastChanged };

          const roomIndex = rooms.value.findIndex(
            r => room.roomId === r.roomId
          );

          if (roomIndex !== -1) {
            rooms.value[roomIndex] = { ...room };
            rooms.value = [...rooms.value];
          }
        }
      );
      roomsListeners.value.push(listener);
    });
  });
}

function addRoom() {
  resetForms();
  addNewRoom.value = true;
}

async function createRoom() {
  disableForm.value = true;

  const { id } = await firestoreService.addUser({
    username: addRoomUsername.value
  });
  await firestoreService.updateUser(id, { _id: id });

  await firestoreService.addRoom({
    users: [id, props.currentUserId],
    lastUpdated: new Date()
  });

  addNewRoom.value = false;
  addRoomUsername.value = '';
  fetchRooms();
}

function inviteUser(roomId) {
  resetForms();
  inviteRoomId.value = roomId;
}

async function addRoomUser() {
  disableForm.value = true;

  const { id } = await firestoreService.addUser({
    username: invitedUsername.value
  });
  await firestoreService.updateUser(id, { _id: id });

  await firestoreService.addRoomUser(inviteRoomId.value, id);

  inviteRoomId.value = null;
  invitedUsername.value = '';
  fetchRooms();
}

function removeUser(roomId) {
  resetForms();
  removeRoomId.value = roomId;
  removeUsers.value = rooms.value.find(room => room.roomId === roomId)?.users || [];
}

async function deleteRoomUser() {
  disableForm.value = true;

  await firestoreService.removeRoomUser(
    removeRoomId.value,
    removeUserId.value
  );

  removeRoomId.value = null;
  removeUserId.value = '';
  fetchRooms();
}

async function deleteRoom(roomId) {
  const room = rooms.value.find(r => r.roomId === roomId);
  if (
    room.users.find(user => user._id === 'SGmFnBZB4xxMv9V4CVlW') ||
    room.users.find(user => user._id === '6jMsIXUrBHBj7o2cRlau')
  ) {
    return alert('Nope, for demo purposes you cannot delete this room');
  }

  firestoreService.getMessages(roomId).then(({ data }) => {
    data.forEach(message => {
      firestoreService.deleteMessage(roomId, message.id);
      if (message.files) {
        message.files.forEach(file => {
          storageService.deleteFile(props.currentUserId, message.id, file);
        });
      }
    });
  });

  await firestoreService.deleteRoom(roomId);

  fetchRooms();
}

function resetForms() {
  disableForm.value = false;
  addNewRoom.value = null;
  addRoomUsername.value = '';
  inviteRoomId.value = null;
  invitedUsername.value = '';
  removeRoomId.value = null;
  removeUserId.value = '';
}

</script>

<template>
	<div class="window-container" :class="{ 'window-mobile': isDevice }">
		<!-- <p>INNER INJECT {{projectId}}</p> -->
		<form v-if="addNewRoom" @submit.prevent="createRoom">
			<input v-model="addRoomUsername" type="text" placeholder="Add session name" />
			<button type="submit" :disabled="disableForm || !addRoomUsername">
				New Session
			</button>
			<button class="button-cancel" @click="addNewRoom = false">Cancel</button>
		</form>

		<form v-if="inviteRoomId" @submit.prevent="addRoomUser">
			<input v-model="invitedUsername" type="text" placeholder="Add username" />
			<button type="submit" :disabled="disableForm || !invitedUsername">
				Add User
			</button>
			<button class="button-cancel" @click="inviteRoomId = null">Cancel</button>
		</form>

		<form v-if="removeRoomId" @submit.prevent="deleteRoomUser">
			<select v-model="removeUserId">
				<option default value="">Select User</option>
				<option v-for="user in removeUsers" :key="user._id" :value="user._id">
					{{ user.username }}
				</option>
			</select>
			<button type="submit" :disabled="disableForm || !removeUserId">
				Remove User
			</button>
			<button class="button-cancel" @click="removeRoomId = null">Cancel</button>
		</form>

        <client-only>
		<ChatContainer
			ref="chatWindow"
			:height="screenHeight"
			:theme="theme"
			:styles="JSON.stringify(styles)"
			:current-user-id="currentUserId"
			:room-id="roomId"
			:rooms="JSON.stringify(loadedRooms)"
			:loading-rooms="loadingRooms"
			:rooms-loaded="roomsLoaded"
			:messages="JSON.stringify(messages)"
			:messages-loaded="messagesLoaded"
			:room-message="roomMessage"
			:room-actions="JSON.stringify(roomActions)"
			:menu-actions="JSON.stringify(menuActions)"
			:message-selection-actions="JSON.stringify(messageSelectionActions)"
			:templates-text="JSON.stringify(templatesText)"
			:showSendIcon="showSendIcon"
			@fetch-more-rooms="fetchMoreRooms"
			@fetch-messages="fetchMessages($event)"
			@send-message="sendMessage($event)"
			@edit-message="editMessage($event)"
			@delete-message="deleteMessage($event)"
			@open-file="openFile($event)"
			@open-user-tag="openUserTag($event)"
			@add-room="addRoom($event)"
			@room-action-handler="menuActionHandler($event)"
			@menu-action-handler="menuActionHandler($event)"
			@message-selection-action-handler="
				messageSelectionActionHandler($event)
			"
			@send-message-reaction="sendMessageReaction($event)"
			@typing-message="typingMessage($event)"
			@toggle-rooms-list="$emit('show-demo-options', $event.opened)"
		>
			<!-- <div
				v-for="message in messages"
				:slot="'message_' + message._id"
				:key="message._id"
			>
				New message container
			</div> -->
		</ChatContainer>
    </client-only>
	</div>
</template>

<style lang="scss" scoped>
.window-container {
	width: 100%;
}

.window-mobile {
	form {
		padding: 0 10px 10px;
	}
}

form {
	padding-bottom: 20px;
}

input {
	padding: 5px;
	width: 140px;
	height: 21px;
	border-radius: 4px;
	border: 1px solid #d2d6da;
	outline: none;
	font-size: 14px;
	vertical-align: middle;

	&::placeholder {
		color: #9ca6af;
	}
}

button {
	background: #1976d2;
	color: #fff;
	outline: none;
	cursor: pointer;
	border-radius: 4px;
	padding: 8px 12px;
	margin-left: 10px;
	border: none;
	font-size: 14px;
	transition: 0.3s;
	vertical-align: middle;

	&:hover {
		opacity: 0.8;
	}

	&:active {
		opacity: 0.6;
	}

	&:disabled {
		cursor: initial;
		background: #c6c9cc;
		opacity: 0.6;
	}
}

.button-cancel {
	color: #a8aeb3;
	background: none;
	margin-left: 5px;
}

select {
	vertical-align: middle;
	height: 33px;
	width: 152px;
	font-size: 13px;
	margin: 0 !important;
}
</style>