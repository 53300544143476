import revive_payload_client_mU4KUf4dbW from "/Users/jdo/org/brx/artifacts_frontend_brx/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_ncs7xl7yc6rpvzqqx3cthtqpby/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_IZUk9YCXFF from "/Users/jdo/org/brx/artifacts_frontend_brx/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_ncs7xl7yc6rpvzqqx3cthtqpby/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_yHlCC7wnCK from "/Users/jdo/org/brx/artifacts_frontend_brx/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_ncs7xl7yc6rpvzqqx3cthtqpby/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_qTgBYbYqTS from "/Users/jdo/org/brx/artifacts_frontend_brx/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_ncs7xl7yc6rpvzqqx3cthtqpby/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_7MwCPEBF9Q from "/Users/jdo/org/brx/artifacts_frontend_brx/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_ncs7xl7yc6rpvzqqx3cthtqpby/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_6aFcMcF2jx from "/Users/jdo/org/brx/artifacts_frontend_brx/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_ncs7xl7yc6rpvzqqx3cthtqpby/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_BiKplqwfTF from "/Users/jdo/org/brx/artifacts_frontend_brx/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_ncs7xl7yc6rpvzqqx3cthtqpby/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/Users/jdo/org/brx/artifacts_frontend_brx/.nuxt/components.plugin.mjs";
import prefetch_client_wBFH0ZSNnU from "/Users/jdo/org/brx/artifacts_frontend_brx/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_ncs7xl7yc6rpvzqqx3cthtqpby/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import firebase_Wjz9XrZo01 from "/Users/jdo/org/brx/artifacts_frontend_brx/plugins/firebase.ts";
import emoji_picker_KzpRdgEzIK from "/Users/jdo/org/brx/artifacts_frontend_brx/plugins/emoji-picker.js";
export default [
  revive_payload_client_mU4KUf4dbW,
  unhead_IZUk9YCXFF,
  router_yHlCC7wnCK,
  payload_client_qTgBYbYqTS,
  navigation_repaint_client_7MwCPEBF9Q,
  check_outdated_build_client_6aFcMcF2jx,
  chunk_reload_client_BiKplqwfTF,
  components_plugin_KR1HBZs4kY,
  prefetch_client_wBFH0ZSNnU,
  firebase_Wjz9XrZo01,
  emoji_picker_KzpRdgEzIK
]