// composables/useAuth.ts
import { ref, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { signOut, onAuthStateChanged, getRedirectResult, signInWithCustomToken, getAuth } from 'firebase/auth'

export function useAuth() {
  const user = ref(null)
  const error = ref(null)
  const router = useRouter()

  const getReturnUrl = () => {
    return encodeURIComponent('https://artifacts.brx.ai')
  }

  const logout = async () => {
    try {
      const auth = getAuth()
      await signOut(auth)
      console.log('User logged out successfully')
      window.location.href = `https://brx.ai/login?returnUrl=${getReturnUrl()}`
    } catch (error) {
      console.error('Logout failed:', error)
      error.value = error
    }
  }

  const login = () => {
    window.location.href = `https://brx.ai/login?returnUrl=${getReturnUrl()}`
  }

  const checkAuthWithSessionToken = async () => {
    const sessionToken = document.cookie
      .split('; ')
      .find(row => row.startsWith('__session='))
      ?.split('=')[1];

    console.log("FOUND SESSION TOKEN", sessionToken)

    if (!sessionToken) {
      console.error('No session token found')
      return
    }

    try {
      const response = await fetch('https://us-central1-brx-frontend.cloudfunctions.net/verifySessionToken', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sessionToken }),
      })

      if (response.ok) {
        const { customToken } = await response.json()
        const auth = getAuth()
        await signInWithCustomToken(auth, customToken)
        console.log('User authenticated successfully with session token')
      } else {
        throw new Error('Session token verification failed')
      }
    } catch (error) {
      console.error('Auth check failed:', error)
      error.value = error
    }
  }

  onMounted(async () => {
    const auth = getAuth()
    getRedirectResult(auth).catch((reason) => {
      console.log("No redirect?", reason)
      error.value = reason
    })

    await checkAuthWithSessionToken()
    
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      console.log("Checking AUTH", currentUser)
      
      if (currentUser) {
        user.value = currentUser
      } else {
        await checkAuthWithSessionToken()
      }
    })

    onUnmounted(() => unsubscribe())
  })

  return {
    user,
    error,
    logout,
    login,
    checkAuthWithSessionToken
  }
}