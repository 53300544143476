<!-- File: components/CreateProjectPopup.vue -->
<template>
  <Dialog :open="isOpen" @update:open="handleUpdateOpen">
    <DialogContent class="sm:max-w-[1000px]">
      <DialogHeader>
        <DialogTitle>Create New Project</DialogTitle>
        <DialogDescription>
          Enter the details for your new project.
        </DialogDescription>
      </DialogHeader>
      <div class="grid gap-4 py-4">
        <div
            class="app-container"
            :class="{ 'app-mobile': isDevice, 'app-mobile-dark': theme === 'dark' }"
            >
            <!-- <p v-if="project" class="project-info">
                Current Project: {{ project.name }} (ID: {{ project.id }})
            </p> -->
            <!-- <button @click="resetData">RESET</button>
            <button @click="addDataOnce">AddDATAONCE</button> -->
            <client-only>
                <chat-container
                v-if="showChat && user"
                :current-user-id="user.uid"
                :theme="theme"
                :is-device="isDevice"
                @selected-room="current_room_id = $event"
                @show-demo-options="showDemoOptions = $event"
                @messageSelectionActionHandler="messageSelectionActionHandler($event)"
                />
            </client-only>
            <div v-if="!user">
                <p>Please log in to access the chat.</p>
                <button @click="login">Login</button>
            </div>
            </div>
      </div>
      <DialogFooter>
        <!-- <Button @click="createProject">Create</Button> -->
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>

<script setup>
import { ref, inject , computed, watch, onMounted, toRaw} from 'vue'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Label } from '@/components/ui/label'

import FirestoreManager from '@/components/chat/database/firestore';
import FirebaseStatusManager from '@/components/chat/database/firebase';
import FirebaseStorageManager from '@/components/chat/database/storage';
import { useAuth } from '@/composables/useAuth';
import ChatContainer from '@/components/chat/ChatContainerCreate';
import BRX from "brx-node";

const nuxtApp = useNuxtApp();
let firestoreService = new FirestoreManager(nuxtApp.$firestore);
let firebaseService = new FirebaseStatusManager(nuxtApp.$realtimeDb);
let storageService = new FirebaseStorageManager(nuxtApp.$storage);

const { user, error, logout, login } = useAuth();

const theme = ref('dark');
const showChat = ref(true);
const current_room_id = ref('');
const isDevice = ref(false);
const showDemoOptions = ref(true);
const updatingData = ref(false);

const show_brx_ops = ref(false);
const show_brx_guided_ops = ref(false);

const isOpen = inject('isCreateProjectPopupOpen')
const closePopup = inject('closeCreateProjectPopup')

// Computed property in Vue 3
const showOptions = computed(() => !isDevice.value || showDemoOptions.value)

watch(user, () => {
  showChat.value = false
  setTimeout(() => (showChat.value = true), 150)
})

onMounted(() => {
  isDevice.value = window.innerWidth < 500
  window.addEventListener('resize', ev => {
    if (ev.isTrusted) isDevice.value = window.innerWidth < 500
  })
})

const handleUpdateOpen = (value) => {
  if (!value) {
    closePopup()
  }
}


const resetData = async () => {
	// console.log(firestoreService.firestoreDb)
	const rooms = await firestoreService.getAllRooms()
  console.log("Deleteing")
  console.log(rooms)
	rooms.data.forEach(async room => {
		const messages = await firestoreService.getMessages(room.id)
		messages.data.forEach(message => {
			firestoreService.deleteMessage(room.id, message.id)
			if (message.files) {
				message.files.forEach(file => {
					storageService.deleteFile(currentUserId.value, message.id, file)
				})
			}
		})
		firestoreService.deleteRoom(room.id)
	})

	const allUsers = await firestoreService.getAllUsers()
  console.log("deleting")
  console.log(allUsers)
	allUsers.data.forEach(user => {
		firestoreService.deleteUser(user.id)
	})
}

const addDataOnce = async () => {
	updatingData.value = true

  console.log(user.value)
  let tval = {
					_id: user.value.uid,
					username: user.value.displayName,
          email: user.value.email,
					avatar: user.value.photoURL
				}
  console.log(tval)
  console.log("-=-=-=-=-=-==-=-=-=-=")
	await firestoreService.addIdentifiedUser(user.value.uid, tval)
  await firestoreService.addRoom({ users: [user.value.uid], lastUpdated: new Date() })

	updatingData.value = false
	location.reload()
}

</script>